<template>
    <div class="uk-card uk-card-default uk-card-small tm-ignore-container">
        <header class="uk-card-header"
                style="border-top: 3px solid rgb(1, 176, 255) !important;">
            <div class="uk-grid-small uk-flex-middle" uk-grid>
                <div class="uk-width-1-1 uk-width-expand@s uk-flex
                        uk-flex-center uk-flex-left@s uk-text-small">

                    <div class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input type="text" v-on:keyup="searchByName($event)"
                                   :placeholder="$t('search')"
                                   class="uk-input uk-form-width-medium"
                                   v-model="name"/>
                        </div>
                        <button type="button" @click="searchBy()"
                                class="uk-button uk-button-small uk-button-primary uk-margin-small-left"><span
                                uk-icon="search"></span></button>
                    </div>

                </div>

                <div class="uk-width-1-1 uk-width-auto@s uk-flex uk-flex-center uk-flex-middle">
                    <a class="uk-button uk-button-default uk-button-small uk-hidden@m"
                       style="color: rgb(1, 176, 255); border-color: rgb(1, 176, 255)"
                       href="javascript:void(0)" @click="createModal"><span
                            class="uk-margin-xsmall-right"
                            uk-icon="icon: plus; ratio: .95;"
                    ></span>{{ $t('createGroup') }}</a>

                    <div class="tm-change-view uk-margin-small-left">
                        <ul class="uk-subnav uk-iconnav js-change-view">
                            <li>
                                <a class="uk-button uk-button-default uk-button-small"
                                   style="color: rgb(1, 176, 255); border-color: rgb(1, 176, 255)"
                                   href="javascript:void(0)" @click="createModal"><span
                                        class="uk-margin-xsmall-right"
                                        uk-icon="icon: plus; ratio: .95;"
                                ></span>{{ $t('createGroup') }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
        <div class="uk-card-body">
            <table v-if="groups.length > 0"
                   class="uk-table uk-table-hover uk-table-striped uk-table-responsive"
                   style="width:100%">
                <thead>
                <tr>
                    <th>{{ $t('groupName') }}</th>
                    <th>{{ $t('stuCount') }}</th>
                    <th>{{ $t('proCount') }}</th>
                    <th>{{ $t('creator') }}</th>
                    <th>{{ $t('createdAt') }}</th>
                    <th>{{ $t('action') }}</th>
                </tr>
                </thead>
                <tbody v-if="groups.length > 0">

                <tr v-for="item in groups"
                    :item="item"
                    :key="item.id">
                    <td>{{item.groupName}}</td>
                    <td>
                        <a @click="mapping(item, 'student')" uk-toggle href="#modal-mapping">
                          <span v-if="item.stCount == null">0</span>
                          <span v-else>{{item.stCount}}</span>
                        </a>
                    </td>
                    <td>
                      <a @click="mapping(item, 'prof')" uk-toggle href="#modal-mapping">
                        <span v-if="item.proCount == null">0</span>
                        <span v-else>{{item.proCount}}</span>
                      </a>
                    </td>
                    <td>{{item.creator}}</td>
                    <td>{{item.createdAt|moment}}</td>
                    <td>
                        <a @click="edit(item)"><span uk-icon="icon: file-edit; ratio: 1.5;"></span></a>
                        <a class="uk-text-danger" @click="deletegroup(item.id)"><span
                                uk-icon="icon: trash; ratio: 1.5;"></span></a>
                    </td>
                </tr>
                </tbody>
            </table>
            <div v-else class="uk-alert-primary " uk-alert>
                <p class="uk-text-center">Empty.</p>
            </div>
        </div>
        <div class="uk-card-footer uk-text-center">
            <div class="uk-flex uk-flex-center">
                <v-pagination v-model="currentPage"
                  :page-count="pages"
                  :classes="uikitClasses"
                  :labels="Labels"
                  @change="onChange">
                </v-pagination>
            </div>
        </div>
        <div id="modal-create" uk-modal>
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default"  uk-close></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">{{ $t('cStuGroup') }}</h2>
                </div>
                <div class="uk-modal-body" uk-overflow-auto>
                    <div class="uk-width-1-1">
                        <div v-if="grouperr" class="uk-alert-danger" uk-alert>
                            <p>{{errmsg}}</p>
                        </div>
                        <label><b>{{$t('groupName')}}</b>
                            <input v-on:keyup="nameChange($event)" class="uk-input uk-form-large"
                                   :class="{ 'uk-form-danger': grouperr == true }"
                                   v-model="groupName" type="text"/>
                        </label>
                    </div>
<!--                    <div class="uk-width-1-1">-->
<!--                      <div v-if="grouperr" class="uk-alert-danger" uk-alert>-->
<!--                        <p>{{errmsg}}</p>-->
<!--                      </div>-->
<!--                      <label><b>Meet URL</b>-->
<!--                        <input v-on:keyup="nameChange($event)" class="uk-input uk-form-large"-->
<!--                               :class="{ 'uk-form-danger': grouperr == true }"-->
<!--                               v-model="groupMeetUrl" type="url"/>-->
<!--                      </label>-->
<!--                    </div>-->
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button type="button" class="uk-button uk-button-default uk-modal-close" >{{ $t('cancel') }}</button>
                    <button type="button" class="uk-button uk-button-primary uk-margin-small-left" @click="save" >{{ $t('save') }}
                    </button>
                </div>
            </div>
        </div>

        <div id="modal-mapping" class="uk-modal-container" uk-modal>
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default"  uk-close></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">{{currentGroup.name}}</h2>
                </div>
                <div class="uk-modal-body" uk-overflow-auto>
                    <div class="uk-width-1-1">

                        <form @submit.prevent="getStudents" method="post" class="uk-form-stacked">
                            <div class="uk-grid-medium uk-child-width-1-2" uk-grid>
                                <fieldset class="uk-fieldset uk-width-1-1">
                                    <div class="uk-grid-small uk-child-width-1-1 uk-child-width-1-2@s" uk-grid>
                                        <div>
                                            <label>
                                                <div class="uk-form-label">{{ $t('name') }}</div>
                                                <input v-model="currentGroup.fname" class="uk-input" type="text"/>
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                <div class="uk-form-label">{{ $t('ID') }}</div>
                                                <input class="uk-input" type="text" v-model="currentGroup.username"/>
                                            </label>
                                        </div>

                                        <div>
                                            <label>
                                                <div class="uk-form-label">{{ $t('department') }}</div>
                                                <input class="uk-input" type="text"
                                                       v-model="currentGroup.department"/></label>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="uk-width-1-1 uk-text-right uk-margin-small">
                                <button type="submit" class="uk-button uk-button-small uk-button-primary uk-text-right">
                                    <span data-v-b11fd5bc="" uk-icon="icon: search; ratio: .95;" class="uk-icon"></span>
                                  {{ $t('search') }}
                                </button>
                            </div>
                        </form>
                        <hr>

                        <div uk-grid class="uk-text-center uk-flex-center uk-margin-small-top">
                            <div class="uk-width-2-5@m">
                                <user-list :users="currentGroup.llist"></user-list>
                            </div>
                            <div class="uk-width-auto@m">
                                <div class="uk-flex uk-flex-center uk-height-1-1">
                                    <ul class="uk-list" style="margin: auto">
                                        <li >
                                            <button type="button" @click="pushuser"
                                                    class="uk-button uk-button-default uk-padding-remove">
                                                <span uk-icon="icon: chevron-right; ratio:2.5"></span>
                                            </button>
                                        </li>
                                        <li >
                                            <button type="button" @click="pulluser"
                                                    class="uk-button uk-button-default uk-padding-remove">
                                                <span uk-icon="icon: chevron-left; ratio:2.5"></span>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="uk-width-2-5@m">
                                <user-list :users="currentGroup.rlist"></user-list>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button type="button" class="uk-button uk-button-default uk-modal-close" >{{ $t('cancel') }}</button>
                    <button type="button" class="uk-button uk-button-primary uk-margin-small-left" @click="savemapping" >
                        <span data-v-b11fd5bc="" uk-icon="icon: check; ratio: .95;" class="uk-icon"></span>
                      {{ $t('save') }}
                    </button>
                </div>
            </div>
        </div>
      <div id="already" class="uk-modal-container" uk-modal>
        <div class="uk-modal-dialog">
          <button class="uk-modal-close-default"  uk-close></button>
          <div class="uk-modal-header">
            <h2 class="uk-modal-title uk-text-center">Already registered</h2>
          </div>
          <div class="uk-modal-body" uk-overflow-auto>
            <div class="uk-width-1-1">
                <table class="uk-table uk-table-divider">
                    <thead>
                      <tr>
                          <th>No</th>
                          <th>Login Id</th>
                          <th>Name</th>
                          <th>User Id</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in already" :key="index">
                            <th>{{ index + 1 }}</th>
                            <th>{{ item.loginId }}</th>
                            <th>{{ item.name }}</th>
                            <th>{{ item.id }}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
          </div>
          <div class="uk-modal-footer uk-text-right">
            <button type="button" class="uk-button uk-button-default uk-modal-close" >{{ $t('cancel') }}</button>
          </div>
        </div>
      </div>

    </div>
</template>
<script scoped>
    import moment from 'moment'
    import vPagination from '@/components/globals/vue-plain-pagination'
    import {mapGetters} from 'vuex'
    import GroupService from "../../services/GroupService";
    import UIkit from "uikit";
    import UserList from './comp/UserList'

    export default {
        name: 'GroupList',
        components: {
            vPagination,
            UserList
        },
        data() {
            return {
                name: "",
                totalElements: '',
                groups: [],
                currentPage: 1,
                pages: 0,
                uikitClasses: {
                    ul: 'uk-pagination',
                    li: '',
                    liActive: 'uk-active',
                    liDisable: 'uk-disabled',
                    button: 'page-link'
                },
                Labels: {
                    first: 'First',
                    prev: '<span uk-pagination-previous></span>',
                    next: '<span uk-pagination-next></span>',
                    last: 'Last'
                },
                groupId: 0,
                groupName: "",
                grouperr: false,
                errmsg: "",
                already: [],
                currentGroup: {
                    name: "", id: 0, rlist: [], llist: [], fname: '', department: '', grade: '0', username: '', type: ''
                }

            }
        },
        created() {
            UIkit.util.on('#sortable', 'moved', function (item) {
                console.log(item.detail[1].id)
                UIkit.notification(`Card is ${item.detail[1].id}`, 'success');
            });
        },
        filters: {
            moment: function (date) {
                return moment(date).format('YYYY.MM.DD')
                // return moment(date).format('YYYY-MM-DD, h:mm:ss a')
            }
        },
        methods: {
            pushuser() {
                let stackarray = []

                this.currentGroup.llist.map((item) => {
                    if (item.select == true) {
                        item.select = false
                        this.currentGroup.rlist.push(item)
                    } else {
                        stackarray.push(item)
                    }
                })
                this.currentGroup.llist = stackarray

            },
            pulluser() {
                let stackarray = []
                this.currentGroup.rlist.map((item) => {
                    if (item.select) {
                        item.select = false
                        this.currentGroup.llist.push(item)
                    } else {
                        stackarray.push(item)
                    }
                })
                this.currentGroup.rlist = stackarray
            },
            mapping(item, type) {
                this.st = type
                this.currentGroup.name = item.groupName
                this.currentGroup.id = item.id
                this.currentGroup.rlist = []
                this.currentGroup.llist = []
                this.currentGroup.ispush = false
                this.currentGroup.fname = ''
                this.currentGroup.department = ''
                this.currentGroup.grade = '0'
                this.currentGroup.username = ''
                this.currentGroup.type = type
                this.getStudents()
                // UIkit.modal('#modal-mapping', null).show();
            },
            async getStudents() {
                try {
                    this.currentGroup.rlist = []
                    this.currentGroup.llist = []

                    const response = await GroupService.students({
                        groupid: this.currentGroup.id,
                        orgid: this.currentUser.organizs[0].organizId,
                        department: this.currentGroup.department,
                        grade: this.currentGroup.grade,
                        name: this.currentGroup.fname,
                        username: this.currentGroup.username,
                        role: this.currentGroup.type
                    });
                    response.data.map((item) => {
                        if (item.group_id == 0) {
                            item.new = true
                            item.select = false
                            this.currentGroup.llist.push(item)
                        } else {
                            item.new = false
                            item.select = false
                            this.currentGroup.rlist.push(item)
                        }
                    });
                } catch (error) {
                    console.log(error)
                    alert(error.response.data.message)
                    this.scrollToTop()
                }
            },
            async savemapping() {
                try {
                    let hasaharray = []
                    this.currentGroup.llist.map((item) => {
                        if (item.new == false) {
                            hasaharray.push(item)
                        }
                    })
                    let nemeharray = []
                    this.currentGroup.rlist.map((item) => {
                        if (item.new == true) {
                            nemeharray.push(item)
                        }
                    })

                    if (hasaharray.length == 0 && nemeharray.length == 0) {
                        alert("Please group mapping")
                        return;
                    }

                    const response = await GroupService.pushStudents({
                        orgid: this.currentUser.organizs[0].organizId,
                        groupId: this.currentGroup.id,
                        deleteusers: hasaharray,
                        addusers: nemeharray,
                        role: this.st
                    })
                   console.log(response.data)
                  var _this = this
                    if (response.data.status == 200) {
                        if (response.data.success == true) {
                          if (response.data.result.already.length > 0)
                          {
                            for (let i = 0; i < response.data.result.already.length; i++)
                            {
                              var answers = response.data.result.already[i].split("_");
                              let ans = {
                                id: answers[0],
                                loginId: answers[1],
                                name: answers[2]
                              };
                              _this.already.push(ans)
                            }
                            UIkit.modal('#already', null).show();
                          }
                            alert(response.data.message)
                            this.currentPage = 1
                            this.loaddata()
                            UIkit.modal('#modal-mapping', null).hide();
                        } else {
                            alert(response.data.message)
                        }
                    }

                } catch (err) {
                    console.log(err)
                }

            },
            async deletegroup(id) {

                if (window.confirm('Are you sure?')) {
                    try {
                        const response = await GroupService.delete({
                            orgid: this.currentUser.organizs[0].organizId,
                            groupId: id

                        })
                        if (response.data.status == 200) {
                            if (response.data.success == true) {
                                alert(response.data.message)
                                this.currentPage = 1
                                this.loaddata()
                                UIkit.modal('#modal-mapping', null).hide();
                            } else {
                                alert(response.data.message)
                            }
                        }

                    } catch (err) {
                        alert(err.response.data.message)
                    }
                }


            },
            nameChange(event) {
                if (event.keyCode == 13) {
                    this.save()
                } else {
                    this.grouperr = false
                    this.errmsg = ""
                }
            },
            edit(item) {
                this.groupId = item.id
                this.groupName = item.groupName
                UIkit.modal('#modal-create', null).show();
            },
            async save() {
                if (this.groupName.length == 0) {
                    this.grouperr = true
                    this.errmsg = "Please write group name"
                    return
                }
                // if (this.groupMeetUrl.length == 0) {
                //   this.grouperr = true
                //   this.errmsg = "Please write group meet URL"
                //   return
                // }
                try {
                    const response = await GroupService.createOrModify({
                        orgid: this.currentUser.organizs[0].organizId,
                        groupId: this.groupId,
                        groupName: this.groupName,
                    })
                    if (response.data.status == 200) {
                        if (response.data.success == true) {
                            alert(response.data.message)
                            this.currentPage = 1
                            this.loaddata()
                            UIkit.modal('#modal-create', null).hide();
                        } else {
                            this.grouperr = true
                            this.errmsg = response.data.message
                        }
                    }

                } catch (err) {
                    this.errmsg = err.response.data.message
                    this.grouperr = true
                }

            },
            createModal() {
                this.groupId = 0
                this.groupName = "";
                UIkit.modal('#modal-create', null).show();
            },
            searchByName(event) {
                if (event.keyCode == 13 || this.name.length == 0) {
                    this.currentPage = 1
                    this.loaddata(this.currentPage)
                }
            },
            searchBy() {
                if (this.name.length > 0) {
                    this.currentPage = 1
                    this.loaddata(this.currentPage)
                }
            },
            async loaddata(page) {
                try {
                    if (page > 0) {
                        page = page - 1
                    }
                    const response = await GroupService.index({
                        name: this.name,
                        page: page,
                        orgid: this.currentUser.organizs[0].organizId
                    });
                    if (response.status == 200) {
                        this.groups = response.data.content;
                        // this.currentPage = response.data.page;
                        this.pages = response.data.totalPages;
                        this.totalElements = response.data.totalElements;
                        if (!this.groups.length) {
                            this.isLoading = false
                            return
                        }
                    }
                } catch (error) {
                    console.log(error.response)
                    this.error = error.response.data.message;
                    this.scrollToTop()
                }

            },
            scrollToTop() {
                window.scrollTo(0, 0)
            }
            ,
            onChange: function () {
                this.loaddata(this.currentPage)
            }
        },
        computed: {
            ...mapGetters(['isLoggedIn', 'currentUser'])

        }

    }
</script>

<style scoped>


    .uk-pagination > li > a {
        font-size: 18px !important;
    }

</style>
