import Api from '@/services/Api'

const resource = '/group'
export default {
    index(params) {
        return Api().get(`${resource}/list`, {
            params: params
        })
    },
    createOrModify(group) {
        return Api().post(`${resource}/create`, group)
    },
    delete(group) {
        return Api().post(`${resource}/delete`, group)
    },
    students(params) {
        return Api().get(`${resource}/users`, {
            params: params
        })
    },
    pushStudents(students) {
        return Api().post(`${resource}/pushst`, students)
    },
    groupstudents(params) {
        return Api().get(`${resource}/students`, {
            params: params
        })
    },

    groups(params) {
        return Api().get(`${resource}/groups`, {
            params: params
        })
    },
    setExamCode(params) {
        return Api().post(`${resource}/setexamcode`, params)
    },
    removeExamCode(params) {
        return Api().post(`${resource}/removeexamcode`, params)
    }
}
