<template>
    <div>
        <!--<h4>-->
            <!--<form onkeydown="return event.key != 'Enter';" class="uk-search uk-search-default uk-width-1-1">-->
                <!--<span uk-search-icon></span>-->
                <!--<input class="uk-search-input" type="search" v-model="searchQuery" placeholder="Name,Loginid,Email,Department,Grade"/>-->
            <!--</form>-->
        <!--</h4>-->
        <p class="uk-text-left">
            <button type="button"  @click="selectAll"
                    class="uk-button uk-button-default uk-button-small uk-margin-small-right">
              {{ $t("sAll") }}
            </button>
            <button type="button"  @click="unselectAll" class="uk-button uk-button-default uk-button-small">
              {{ $t("unSelect") }}
            </button>
            <span class="uk-float-right"> {{ $t("result") }}: <b>{{resultQuery.length}}</b></span>
        </p>
        <div class="uk-text-left">{{ $t("sCount") }}:<b>{{selectedCount}}</b></div>
        <div style="border: 1px dashed; overflow-y: scroll; max-height: 550px" class="uk-padding-small">
            <template v-if="resultQuery.length > 0">
                <div :key="index" v-for="(item, index) in resultQuery">
                    <div :class="{ 'uk-card-primary': item.select }"
                         style="padding: 5px;margin-bottom: 10px; cursor: pointer" @click="iselect(item)"
                         class="uk-card uk-card-default uk-card-body uk-card-small">
                        <div class="uk-grid-small uk-flex-middle uk-grid" uk-grid="">
                            <div class="uk-first-column uk-padding-small">

                                <img class="uk-border-circle" v-if="item.image.length > 0"
                                :src="`/uploadingDir/${item.image}`"
                                width="60"
                                :alt="item.image"/>
                                <img v-else class="uk-border-circle" width="45" src="../../../assets/user_pro.png"/>
                            </div>
                            <div class="uk-width-expand uk-text-left">
                                <div><b>{{ $t("name") }}</b>: {{item.first_name}} <br>
                                  <b>{{ $t("ID") }}</b>: {{item.username}}<br>
                                    <b>{{ $t("department") }}</b>: {{item.department}}<br>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="uk-alert-primary" uk-alert>
                    <p>Empty.</p>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'UserList',
        props: {
            users: {
                type: Array,
                default: null
            }
        },
        data() {
            return {
                searchQuery: null,
            }
        },
        methods: {
            iselect(item) {
                item.select = item.select ? false : true
            },
            selectAll() {
                this.resultQuery.map((item) => {
                    item.select = true
                })
            },
            unselectAll() {
                this.users.map((item) => {
                    item.select = false
                })
            }
        },
        computed: {
            resultQuery() {
                if (this.searchQuery) {
                    return this.users.filter((item) => {
                        return this.searchQuery.toLowerCase().split(' ').every(v => (item.first_name + ' ' + item.email + ' ' + item.username + ' ' + item.department + ' ' + item.grade).toLowerCase().includes(v))
                    })
                } else {
                    return this.users;
                }
            },
            selectedCount(){
                let scount = 0
                this.users.map((item) => {
                    if (item.select){
                        scount++
                    }
                })
                return scount
            }
        }
    }
</script>

<style lang="less" scoped>

</style>
